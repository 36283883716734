<template>
  <div class="animated fadeIn">
    <b-card no-body no-focus v-if="isShow">
      <b-tabs card>
        <b-tab title="Хувийн мэдээлэл" active>
          <b-row>
            <b-col md="6">
            <b-card v-if="salesCustomer">
                <h4 class="card-title text-uppercase text-primary">
                  <b-button variant="warning">
                    {{ salesCustomer.status }}
                  </b-button>
                  {{ salesCustomer.lastname }} {{ salesCustomer.firstname }}
                </h4>
                <!-- <h4 class="mb-0"><small class="text-primary">{{ salesCustomer.registerNumber }} {{ salesCustomer.phoneNumber }}</small></h4> -->
                <b-list-group class="mt-4">
                  <b-list-group-item
                      class="pl-0 rounded-0 border-right-0 border-left-0"
                  >
                    <b-row>
                      <b-col sm="6"
                      ><span class="text-muted">Утаc</span>
                        <h5 class="mb-0">
                          {{ salesCustomer.phone }}
                        </h5></b-col
                      >
                      <b-col sm="6"
                      ><span class="text-muted">Регистр</span>
                        <h5 class="mb-0">
                          {{ salesCustomer.register_number }}
                        </h5></b-col
                      >
                    </b-row>
                  </b-list-group-item>
                  <b-list-group-item
                      class="pl-0 rounded-0 border-right-0 border-left-0"
                  >
                    <b-row>
                      <b-col sm="6">
                        <span class="text-muted">И-мэйл</span>
                        <h5 class="mb-0">{{ salesCustomer.email }}</h5>
                      </b-col>
                      <b-col sm="6">
                        <span class="text-muted">Зээл эрх</span>
                        <h5 class="mb-0">
                          {{ salesCustomer.customer_limit }}₮ /
                          <small class="text-muted">
                            {{ salesCustomer.customer_limit }}₮
                          </small>
                        </h5>
                      </b-col>
                    </b-row>
                  </b-list-group-item>
                  <b-list-group-item
                    class="pl-0 rounded-0 border-right-0 border-left-0"
                  >
                    <b-row>
                      <b-col sm="6">
                        <span class="text-muted">Яаралтай үед холбоо барих дугаар</span>
                        <h5 class="mb-0">
                          <span v-if="emergencyNumber">
                            {{emergencyNumber.phone_number}}
                          </span>
                          <span v-else>-</span>
                        </h5>
                      </b-col>
                      <b-col sm="6">
                        <span class="text-muted">
                          Худалдан авалтын зээлийн эрх
                        </span>
                        <h5 class="mb-0">
                          <span v-if="salesCustomer.customer_limit">
                            {{ salesCustomer.customer_limit }}₮ /
                            <small class="text-muted">
                              {{ salesCustomer.customer_limit }}₮
                            </small>
                          </span>
                          <span v-else>-</span>
                        </h5>
                      </b-col>
                    </b-row>
                  </b-list-group-item>
                  <b-list-group-item
                      class="pl-0 rounded-0 border-right-0 border-left-0"
                  >
                    <b-row class="mt-2 mb-2">
                      <b-col sm="12">
                        <span class="text-muted">
                          <h6 class="card-title text-uppercase text-primary">Санал болгож байгаа зээлийн эрхийн хэмжээ</h6>
                        </span>
                      </b-col>
                      <b-col sm="6">
                        <b>v10 /Ердийн хэмжээ/</b>
                      </b-col>
                      <b-col sm="6">
                        <b>v10 Plus /Дээд хэмжээ/</b>

                        <div class="pull-right">
                          <b-button variant="primary" @click="getConfirmCode()"><i class="fa fa-refresh"></i>
                            <span> Шалгах</span>
                          </b-button>
                        </div>
                      </b-col>
                    </b-row>
                    <b-row class="mt-2 mb-2">
                      <b-col sm="6">
                        <b-row class="mt-2 mb-2">
                          <b-col sm="4">
                            <span class="text-muted">Хувь</span>
                            <b-input v-model="newLimit.percent" disabled></b-input>
                          </b-col>
                          <b-col sm="8">
                            <span class="text-muted">Зээлийн эрх</span>
                            <b-input v-model="newLimit.limit" disabled></b-input>
                          </b-col>
                        </b-row>
                        <b-row>
                          <b-col sm="4">
<!--                            <b-input v-model="salesCustomer.customer_new_percent" disabled></b-input>-->
                          </b-col>
                          <b-col sm="8">
                            <span class="text-muted">Худалдан авалтын зээлийн эрх</span>
                            <b-input v-model="newLimit.purchaseLimit" disabled></b-input>
                          </b-col>
                        </b-row>
                      </b-col>
                      <b-col sm="6">
                        <b-row class="mt-2 mb-2">
                          <b-col sm="4">
                            <span class="text-muted">Хувь</span>
                            <b-input v-model="potentialLimit.percent" disabled></b-input>
                          </b-col>
                          <b-col sm="8">
                            <span class="text-muted">Зээлийн эрх</span>
                            <b-input v-model="potentialLimit.limit" disabled></b-input>
                          </b-col>
                        </b-row>
                        <b-row>
                          <b-col sm="4">
                            <!--                            <b-input v-model="salesCustomer.customer_new_percent" disabled></b-input>-->
                          </b-col>
                          <b-col sm="8">
                            <span class="text-muted">Худалдан авалтын зээлийн эрх</span>
                            <b-input v-model="potentialLimit.purchaseLimit" disabled></b-input>
                          </b-col>
                        </b-row>
                      </b-col>
                    </b-row>
                  </b-list-group-item>
                  <b-list-group-item
                      class="pl-0 rounded-0 border-right-0 border-left-0"
                  >
                    <b-row class="mt-2 mb-2">
                      <b-col sm="12">
                        <span class="text-muted">
                          <h6 class="card-title text-uppercase text-primary">Харилцагчийн зөвшөөрсөн зээлийн эрхийг тогтоох</h6>
                        </span>
                      </b-col>
                    </b-row>
                    <b-row class="mt-2 mb-2">
                      <b-col sm="4">
                        <span class="text-muted">Хувь</span>
                        <b-input @blur="changeCurrentPercent()" @change="changeCurrentPercent()" v-model="currentLimit.percent" type="number" :min="newLimit.percent" :max="potentialLimit.percent" step="0.1"></b-input>
                      </b-col>
                      <b-col sm="4">
                        <span class="text-muted">Зээлийн эрх</span>
                        <b-input v-model="currentLimit.limit" disabled></b-input>
                      </b-col>
                      <b-col sm="4">
                        <span class="text-muted">Худалдын авалтын зээлийн эрх</span>
                        <b-input v-model="currentLimit.purchaseLimit" disabled></b-input>
                      </b-col>
                    </b-row>
                    <b-row class="mt-2 mb-2">
                      <b-col sm="12">
                        <div class="pull-right">
                          <b-button variant="primary">Баталгаажуулах</b-button>
                        </div>
                      </b-col>
                    </b-row>
                  </b-list-group-item>
                  <!--              <b-list-group-item-->
                  <!--                  class="pl-0 rounded-0 border-right-0 border-left-0"-->
                  <!--              >-->
                  <!--                <b-row>-->
                  <!--                  <b-col sm="6">-->
                  <!--                    <IsSendSms-->
                  <!--                        :customer="customer"-->
                  <!--                        :callback="loadData"-->
                  <!--                    ></IsSendSms>-->
                  <!--                  </b-col>-->
                  <!--                  <b-col sm="6"-->
                  <!--                  ><span class="text-muted">Битүүмжлэгдсэн дүн</span>-->
                  <!--                    <h5 class="mb-0">-->
                  <!--                            <span v-if="customer.holdAmount">-->
                  <!--                              {{ customer.holdAmount }} ₮-->
                  <!--                              <b-button-->
                  <!--                                  variant="warning"-->
                  <!--                                  class="ml-3 btn-sm"-->
                  <!--                                  @click="showModal"-->
                  <!--                              >-->
                  <!--                                Дэлгэрэнгүй-->
                  <!--                              </b-button>-->
                  <!--                            </span>-->
                  <!--                      <span v-else>-</span>-->
                  <!--                    </h5></b-col-->
                  <!--                  >-->
                  <!--                </b-row>-->
                  <!--              </b-list-group-item>-->
                  <!--              <b-list-group-item-->
                  <!--                  class="pl-0 rounded-0 border-right-0 border-left-0"-->
                  <!--              >-->
                  <!--                <b-row>-->
                  <!--                  <b-col sm="6">-->
                  <!--                          <span class="text-muted"-->
                  <!--                          >Гэрээ байгуулсан ажилтаны имэйл</span-->
                  <!--                          >-->
                  <!--                    <h5 class="mb-0">-->
                  <!--                            <span v-if="customer.contractedUserEmail">{{-->
                  <!--                                customer.contractedUserEmail-->
                  <!--                              }}</span>-->
                  <!--                      <span v-else>-</span>-->
                  <!--                    </h5>-->
                  <!--                  </b-col>-->
                  <!--                  <b-col sm="6">-->
                  <!--                    <ContractedNumber-->
                  <!--                        :customer="customer"-->
                  <!--                        :callback="changeContractNumber"-->
                  <!--                        v-if="-->
                  <!--                              checkPermission('admin.customer.contract-number')-->
                  <!--                            "-->
                  <!--                    ></ContractedNumber>-->
                  <!--                  </b-col>-->
                  <!--                </b-row>-->
                  <!--              </b-list-group-item>-->


                </b-list-group>
            </b-card>
            </b-col>
            <b-col md="6">
              <b-card v-if="salesCustomer">
                <h4 class="card-title text-uppercase text-primary">
                  <b-button variant="warning">
                    {{ salesCustomer.status }}
                  </b-button>
                  Төлөв өөрчлөх
                </h4>
                <b-list-group class="mt-4">
                  <b-list-group-item
                      class="pl-0 rounded-0 border-right-0 border-left-0"
                  >
                    <b-row class="mt-2 mb-2">
                      <b-col sm="12">
                        <span class="text-muted">
                          <h6 class="card-title text-uppercase text-primary">Харилцагчтай холбогдсон эсэх төлөв өөрчлөх</h6>
                        </span>
                      </b-col>
                    </b-row>
                    <b-row class="mt-2 mb-2">
                      <b-col sm="6">
                        <span class="text-muted">Төлөв</span>
                        <treeselect
                            v-model="new_status_status_id"
                            :disable-branch-nodes="true"
                            :show-count="true"
                            :options="statuses"
                        />
                      </b-col>
                      <b-col sm="6">
                        <span class="text-muted">Тайлбар</span>
                        <b-input v-model="new_status.description"></b-input>
                      </b-col>
                    </b-row>
                    <b-row class="mt-2 mb-2">
                      <b-col sm="12">
                        <div class="pull-right">
                          <b-button variant="primary">Хадгалах</b-button>
                        </div>
                      </b-col>
                    </b-row>
                  </b-list-group-item>
                  <b-list-group-item v-for="status_log in salesCustomer.sales_status_log" :key="status_log.id"
                      class="pl-0 rounded-0 border-right-0 border-left-0"
                  >
                    <b-row class="mt-2 mb-2">
                    <b-col sm="12">
                        <span class="text-muted">
                          <h6 class="card-title text-uppercase text-primary">Төлөв өөрчлөгдсөн түүх</h6>
                        </span>
                    </b-col>
                  </b-row>
                    <b-row class="mt-2 mb-2">
                      <b-col sm="4">
                        <span class="text-muted">Өмнөх төлөв</span>
                        <treeselect
                            v-model="status_log.old_status"
                            :disable-branch-nodes="true"
                            :show-count="true"
                            :options="statuses"
                            disabled=""
                        />
                      </b-col>
                      <b-col sm="4">
                        <span class="text-muted">Өөрчлөгдсөн төлөв</span>
                        <treeselect
                            v-model="status_log.new_status"
                            :disable-branch-nodes="true"
                            :show-count="true"
                            :options="statuses"
                            disabled=""
                        />
                      </b-col>
                      <b-col sm="4">
                        <span class="text-muted">Тайлбар</span>
                        <b-input v-model="status_log.description" disabled></b-input>
                      </b-col>
                    </b-row>
                  </b-list-group-item>
                </b-list-group>
              </b-card>
            </b-col>
          </b-row>
        </b-tab>
      </b-tabs>
    </b-card>
    <b-modal ref="confirm-code-modal" title="Зөвшөөрлийг баталгаажуулах" hide-footer>
<!--      <loading-->
<!--          :active.sync="isLoading"-->
<!--          :is-full-page="false"-->
<!--          :color="this.$config.LOADER.color"-->
<!--          :opacity="this.$config.LOADER.opacity"-->
<!--          :background-color="this.$config.LOADER.backgroundColor"-->
<!--          :z-index="10000"-->
<!--      ></loading>-->
      <b-form>
        <b-form-group label="Баталгаажуулах код:" label-for="input-change-phone">
<!--          <b-form-select-->
<!--              id="input-change-phone"-->
<!--              v-model="isSendSms"-->
<!--              required-->
<!--              placeholder="Сонгоно уу"-->
<!--              :options="[-->
<!--              { value: true, text: 'Тийм' },-->
<!--              { value: false, text: 'Үгүй' },-->
<!--            ]"-->
<!--          ></b-form-select>-->
          <b-form-input v-model="confirmCode"></b-form-input>
        </b-form-group>
        <div class="pull-right">
          <b-button variant="primary" @click="checkConfirmCode()">Шалгах</b-button>
        </div>
      </b-form>
    </b-modal>
  </div>
</template>
<script>
import moment from "moment";
import axios from "axios";
import { mapGetters } from 'vuex'
import ContractedNumber from "@/views/admin/Customer/Customer/Detail/ContractedNumber.vue";
import IsSendSms from "@/views/admin/Customer/Customer/Detail/IsSendSms.vue";
import Treeselect from "@riophae/vue-treeselect";
export default {
  name: "Admin.Sales",
  computed: {
    ...mapGetters([
      'email'
    ])
  },
  components: {Treeselect, IsSendSms, ContractedNumber, axios },
  props: ["customerId"],
  data: function() {
    return {
      isShow: false,
      salesCustomer: null,
      customerSalesId: null,
      emergencyNumber: null,
      sentConfirmCode: null,
      confirmCode: null,
      currentLimit: {
        percent: null,
        limit: null,
        purchaseLimit: null
      },
      newLimit: {
        percent: null,
        limit: null,
        purchaseLimit: null
      },
      potentialLimit: {
        percent: null,
        limit: null,
        purchaseLimit: null
      },
      new_status: {
        sales_id: 1,
        status_id: 4,
        email: "",
        description: ""
      },
      statuses: []
    };
  },
  created: function() {
    this.customerSalesId = this.$route.params.id
    // this.$data.query.id = this.customerId;
    // this.initData();
    this.loadData();
  },
  watch: {
    // query: {
    //   handler(query) {
    //     this.loadData();
    //   },
    //   deep: true
    // },
    // selected_status: {
    //   handler(selected_status) {
    //     if (selected_status) this.$data.query.status = selected_status.id;
    //     else this.$data.query.status = null;
    //     this.loadData();
    //   },
    //   deep: true
    // },
    // selected_employee: {
    //   handler(selected_employee) {
    //     if (selected_employee) this.$data.query.e_id = selected_employee.id;
    //     else this.$data.query.e_id = null;
    //     this.loadData();
    //   },
    //   deep: true
    // },
    // selectedLoanApplicationType: {
    //   handler() {
    //     this.loadData();
    //   },
    //   deep: true
    // },
  },
  methods: {
    loadData() {
      this.isShow = true;
      this.checkSession();
      this.isLoading = true;
      console.log(this.$data)
      this.$http
          .post(this.$config.TEST_NUMUR_MS_SALES_URL + "sales-detail", {
            sales_id: this.customerSalesId
          })
          .then(
              (response) => {
                console.log("asasasas", response)
                this.isLoading = false;
                this.$data.salesCustomer = response.body.data
                this.$data.emergencyNumber = response.body.data.emergency_number.length > 0 ? response.body.data.emergency_number[0] : null
                this.newLimit.percent = this.$data.salesCustomer.customer_new_percent
                this.newLimit.limit = this.$data.salesCustomer.customer_new_limit
                this.newLimit.purchaseLimit = this.$data.salesCustomer.customer_new_limit * this.$data.salesCustomer.purchase_line_rate
                this.potentialLimit.percent = this.$data.salesCustomer.customer_potential_percent
                this.potentialLimit.limit = this.$data.salesCustomer.customer_potential_limit
                this.potentialLimit.purchaseLimit = this.$data.salesCustomer.customer_potential_limit * this.$data.salesCustomer.purchase_line_rate
                this.currentLimit.percent = this.$data.salesCustomer.customer_new_percent
                this.currentLimit.limit = this.$data.salesCustomer.customer_new_limit
                this.currentLimit.purchaseLimit = this.$data.salesCustomer.customer_new_limit * this.$data.salesCustomer.purchase_line_rate
                // this.$data.items = response.body.data.sales;
                // this.$data.pagination.total = response.body.data.total_customers;
                // this.$data.pagination.from = this.$data.query.per_page * (this.$data.query.page - 1) + 1;
                // this.$data.pagination.to = this.$data.query.per_page * (this.$data.query.page - 1) + response.body.data.sales.length;
              },
              (response) => {
                this.isLoading = false;
              }
          );
      this.$http
          .get(this.$config.TEST_NUMUR_MS_SALES_URL + "status-list", {
            emulateJSON: true
          })
          .then(
              (response) => {
                console.log("amaraa", response);
                this.isLoading = false;
                if (response.body.success) {
                  this.$data.statuses = response.body.data;
                }
                console.log("statuses", this.statuses);
              },
              (response) => {
                this.isLoading = false;
              }
          );
    },
    getConfirmCode() {
      this.$http
          .post(this.$config.TEST_NUMUR_MS_SALES_URL + "get-xyp-confirm-code", {
            username: "99039206"
          })
          .then(
              (response) => {
                this.sentConfirmCode = true
                if (this.sentConfirmCode) {
                  this.$refs["confirm-code-modal"].show();

                }
                console.log("asasasas", response)
              })
    },
    checkConfirmCode() {
      this.$http
          .post(this.$config.TEST_NUMUR_MS_SALES_URL + "check-xyp-confirm-code", {
            username: "99039206",
            confirm_code: this.confirmCode
          })
          .then(
              (response) => {
                this.sentConfirmCode = false
                this.$refs["confirm-code-modal"].hide();
                this.showToast(
                    "Амжилттай",
                    "Амжилттай шалгалаа",
                    "success"
                );
                console.log("asasasas", response)
                this.loadData()
              })


    },
    changeCurrentPercent(){
      if (this.currentLimit.percent < this.newLimit.percent){
        this.currentLimit.percent = this.newLimit.percent
      }
      if (this.currentLimit.percent > this.potentialLimit.percent){
        this.currentLimit.percent = this.potentialLimit.percent
      }

      this.currentLimit.limit = 6953585.65 * this.currentLimit.percent/100
      this.currentLimit.purchaseLimit = this.currentLimit.limit * this.$data.salesCustomer.purchase_line_rate

    }

  }
};
</script>